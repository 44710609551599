<template>
  <div class="row">
    <div class="col-12">
      <card>
        <div slot="header" class="col-12 d-flex justify-content-center justify-content-sm-between flex-wrap">
          <h4 class="card-title">{{ $store.getters['languageMixin/getStringFromLanguage']('Partner List') }}</h4>
          <router-link v-if="$store.getters['auth/haveOneOfPermissions'](['partners/create'])"
                       :to="'/partners/create'"
                       class="btn btn-info btn-wd" wide>
            {{ $store.getters['languageMixin/getStringFromLanguage']('Add New') }}
            <span class="btn-label">
                    <i class="fa fa-plus"></i>
                </span>
          </router-link>
        </div>
        <div>
          <general-data-table
            ref="table"
            :sortable="true"
            :row-key="'id'"
            :key="componentKey"
            @reorder="reorder($event)"
            :api-url="'partners/index'">
            <el-table-column v-for="column in tableColumns"
                             :key="column.label"
                             :min-width="column.minWidth"
                             :align="column.align"
                             :sortable="column.sortable"
                             :prop="column.value"
                             :label="column.label">

              <template slot-scope="scope">

                <div v-if="column.value === 'image'">
                  <img style="width: 150px; height: 150px" :src='imagePath + scope.row[column.value]'/>
                </div>

                <span v-else>{{ scope.row[column.value] }}</span>

              </template>

            </el-table-column>
            <el-table-column
              :min-width="120"
              align="center"
              :label="$store.getters['languageMixin/getStringFromLanguage']('Active')">
              <template slot-scope="props">

                <l-switch v-if="$store.getters['auth/haveOneOfPermissions'](['partners/change-active-status'])"
                          v-model="props.row.is_active" @input="handleActiveStatus(props.row)">
                  <i class="fa fa-check" slot="on"></i>
                  <i class="fa fa-times" slot="off"></i>
                </l-switch>

              </template>
            </el-table-column>
            <el-table-column
              :min-width="120"
              align="center"
              :label="$store.getters['languageMixin/getStringFromLanguage']('Actions')">
              <template slot-scope="scope">

                <template>
                  <router-link v-if="$store.getters['auth/haveOneOfPermissions'](['partners/update'])"
                               v-tooltip.top-center="$store.getters['languageMixin/getStringFromLanguage']('Edit')"
                               class="btn-warning btn-simple btn-link"
                               :to="'/partners/edit/'+scope.row.id">
                    <i class="fa fa-edit"></i>
                  </router-link>

                  <a v-if="$store.getters['auth/haveOneOfPermissions'](['partners/delete'])"
                     v-tooltip.top-center="$store.getters['languageMixin/getStringFromLanguage']('Delete')"
                     class="btn-danger btn-simple btn-link"
                     @click="openDeleteModal(scope.row.id)">
                    <i class="fa fa-times"></i></a>
                </template>


              </template>
            </el-table-column>
          </general-data-table>
        </div>
      </card>
      <delete-modal :visible="deleteModalVisibility"
                    :message="$store.getters['languageMixin/getStringFromLanguage']('Are you sure you want to delete this partner?')"
                    @close="closeDeleteModal()"
                    @confirm="confirmDeleteModal()">
      </delete-modal>
    </div>
  </div>
</template>

<script>
import {DeleteModal as DeleteModal} from '@/components'
import GeneralDataTable from "@/components/GeneralDataTable";
import {TableColumn} from "element-ui";
import FgSelect from "@/components/Inputs/formGroupSelect";
import LSwitch from "../../components/Switch";


export default {
  components: {
    LSwitch,
    GeneralDataTable,
    DeleteModal,
    [TableColumn.name]: TableColumn,
    FgSelect,
  },

  data() {

    return {
      tableColumns: [
        {label: this.$store.getters['languageMixin/getStringFromLanguage']('Name'), value: 'name', minWidth: '200', align: 'center'},
        {label: this.$store.getters['languageMixin/getStringFromLanguage']('Link'), value: 'link', minWidth: '200', align: 'center'},
        {label: this.$store.getters['languageMixin/getStringFromLanguage']('Image'), value: 'image', minWidth: '200', align: 'center'},
      ],

      deleteModalVisibility: false,
      toDeleteId: undefined,
      componentKey: 0,
      imagePath: process.env.VUE_APP_SERVER_IMAGE_URL
    }
  },
  mounted() {
    this.$refs.table.createDragAndDropSorting();
  },
  methods: {
    openDeleteModal(id) {
      this.deleteModalVisibility = true;
      this.toDeleteId = id;
    },

    closeDeleteModal() {
      this.deleteModalVisibility = false;
    },
    async reorder(data) {
      try {
        let body = {};
        let orderData = [];
        data.forEach((row, i) => {
          orderData.push({"id": row.id, "sort_order": i + 1})
        });
        body = {
          data: orderData,
        }

        await this.axios.post("partners/re-order", body);

        this.$notify({
          message: this.$store.getters['languageMixin/getStringFromLanguage']("order updated successfully"),
          timeout: 1000,
          type: 'success'
        });

      } catch (error) {
        this.$notify({
          message: error.response.data.message,
          timeout: 2000,
          type: 'danger'
        })
      }
    },
    handleActiveStatus(row) {
      let data = {
        'is_active': row.is_active,
      }
      this.axios.post("partners/change-active-status/" + row.id, data).then((response) => {
        this.$notify({
          message: this.$store.getters['languageMixin/getStringFromLanguage']("Partner updated successfully"),
          timeout: 1000,
          type: 'success'
        });
      }).catch((error) => {
        console.log(error)
      })
    },
    async confirmDeleteModal() {
      try {
        let data = {
            'id': this.toDeleteId,
        }
        await this.axios.delete("partners/delete", {headers: {}, data});
        this.$refs.table.getData("updateData");
        this.$notify({
          message: this.$store.getters['languageMixin/getStringFromLanguage']("Partner deleted successfully"),
          timeout: 1000,
          type: 'success'
        });
      } catch (error) {
        this.$notify({
          message: error.response.data.message,
          timeout: 2000,
          type: 'danger'
        })
      } finally {
        this.closeDeleteModal();
        this.toDeleteId = undefined;
      }
    },
  },
}
</script>

